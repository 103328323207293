<template>
    <form class="c-form" @submit.prevent="submit" :class="[{'c-form--sending' : form.sending}, {'c-form--sent' : form.sent}]">
        <page-component v-for="(formComp, index) in comp.settings.builder" v-model="formComp.settings.id" :comp="formComp" :errors="form.errors" :key="index"/>
        <div class="c-form__messages">
            <transition name="fadeUp">
              <p v-if="form.sent">Anfrage wurde erfoglreich abgeschickt – Sie
                            erhalten in Kürze eine Rückmeldung von uns!</p>
            </transition>
        </div>
    </form>
</template>

<script>
  import {EventBus} from "@/event-bus";
  import PageComponent from '@/components/PageComponent'

  import axios from 'axios'

  export default {
    name: 'Comp_form',
    props: {
      'comp': Object
    },

    data() {
      return {
        form: {
          sending: false,
          sent: false,
          errors: [],
          fields: {
            name: null,
            company: null,
            email: null,
            message: null,
            file: null,
            fileUrl: null
          }
        }
      }
    },

    methods: {
      submit() {
        let formData = new FormData()
        formData.append('file', this.form.fields.file);

        this.form.sent = false
        this.form.errors = []

        if (this.form.fields.name && this.form.fields.email && this.form.fields.message) {

          this.form.sending = true

          axios.post(`${process.env.VUE_APP_BACKEND_URL}/anfragen/index.php`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
              .then(res => {
                if (res.data.status === true) {

                  this.form.fields.fileUrl = `${process.env.VUE_APP_BACKEND_URL}/anfragen/uploads/${res.data.file}`

                  axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/forms/submit/request?token=${process.env.VUE_APP_TOKEN_FORMS}`, {
                    form: {
                      Name: this.form.fields.name,
                      Unternehmen: this.form.fields.company ? this.form.fields.company : undefined,
                      Email: this.form.fields.email,
                      Foto: this.form.fields.fileUrl ? this.form.fields.fileUrl : undefined,
                      Nachricht: this.form.fields.message
                    }
                  })
                      .then(() => {
                        this.form.fields = {}
                        this.form.errors = []
                        this.form.sending = false
                        this.form.sent = true
                        EventBus.$emit('form sent')
                      })
                      .catch(e => {
                        // eslint-disable-next-line
                        console.error(e)
                      })
                } else {
                  this.form.sending = true
                  axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/forms/submit/request?token=${process.env.VUE_APP_TOKEN_FORMS}`, {
                    form: {
                      Name: this.form.fields.name,
                      Unternehmen: this.form.fields.company ? this.form.fields.company : undefined,
                      Email: this.form.fields.email,
                      Nachricht: this.form.fields.message
                    }
                  })
                      .then(() => {
                        this.form.fields = {}
                        this.form.errors = []
                        this.form.sending = false
                        this.form.sent = true
                        EventBus.$emit('form sent')
                      })
                      .catch(e => {
                        // eslint-disable-next-line
                        console.error(e)
                      })
                }
              })
              .catch(e => {
                // eslint-disable-next-line
                console.error(e)
              })
        }

        this.form.errors = []

        if (!this.form.fields.name) {
          this.form.errors.push({
            type: 'name',
            message: 'Bitte geben Sie einen Namen ein'
          })
        }
        if (!this.form.fields.email) {
          this.form.errors.push({
            type: 'email',
            message: 'Bitte geben Sie eine Email ein'
          })
        }
        if (!this.form.fields.message) {
          this.form.errors.push({
            type: 'message',
            message: 'Bitte geben Sie eine Nachricht ein'
          })
        }

      }
    },

    created() {
      EventBus.$on('textarea', value => {
        this.form.fields.message = value
      })
      EventBus.$on('input', payload => {
        this.form.fields[payload.name] = payload.value
      })
    },

    components: {
      PageComponent
    }
  }
</script>
